.statContainer {
  border-radius: 10px;
  background-color: white;
  width: 550px;
  min-width: fit-content;
  height: 350px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.heading {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.liveIndicatorContainer {
  position: absolute;
  display: flex;
  padding: 5px;
  flex-direction: row;
  align-items: center;
  right: 20px;
  bottom: 0;
}

.liveIndicator {
  position: relative;
  display: inline-block;
  margin-right: 10px; // Adjust the spacing as needed

  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 8px rgba(255, 0, 0, 0.8); // Optional: Add a subtle shadow for depth
  }
}

.title {
  font-weight: bold;
  padding: 5px;
  font-size: 18px;
}

.graphContainer {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  min-height: 300px;
}

.yaxisUnit {
  writing-mode: vertical-rl;
  transform: scale(-1, -1);
  margin-left: 10px;
  height: 100%;
}
