.app {
  text-align: center;
  width: 100%;
  height: fit-content;
  background-color: #cccccc;
}

.contentBody {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.appHeader {
  background-color: #282c34;
  min-height: 5vh;
  overflow: visible;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  padding: 10px;
  font-weight: bold;
  color: white;
}

.timeFrameDropdown {
  border-radius: 10px;
  position: absolute;
  right: 10px;
  border: #282c34;
  background-color: #282c34;
  font-size: 24px;
  padding: 6px;
  z-index: 100;
  top: 15px;
  box-shadow: 0 0 8px #53565e;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 36px;
  color: #53565e;
  width: 100%;
  height: 100vh;
}

.timeFrameDropdownMenu {
  background-color: #282c34;
  z-index: 100;
  border: #282c34;
}

.app-link {
  color: #61dafb;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
